import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { AppState } from '@app/reducers';
import { getPermissionState, hasPermission } from '@app/reducers/auth/permission.helper';
import { PermissionOption } from '@app/reducers/orm/permission/permission.model';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';

import { PermissionState } from '../reducers/auth/auth.model';

@Pipe({
  name: 'permissionCheck',
  pure: false,
  standalone: true,
})
export class PermissionCheckPipe implements PipeTransform, OnDestroy {
  private dataSubs = new Subscription();
  private permissionState: PermissionState;

  public constructor(private store: Store<AppState>) {
    this.dataSubs.add(this.getPermissions());
  }

  public transform(
    permissions: PermissionOption,
    userId?: string | number | 'me',
    departments?: string[] | string | 'any',
  ): boolean {
    return hasPermission(
      {
        userId: userId,
        departments: departments,
        permissions: permissions,
      },
      this.permissionState,
    );
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  private getPermissions() {
    return combineLatest([this.store.select(getPermissionState)]).subscribe({
      next: ([permissionState]: [PermissionState]) => {
        this.permissionState = permissionState;
      },
    });
  }
}
